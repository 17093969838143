import { post } from '@/utils/request'

export function getEdit(data) {
    return post('task/department/editDepar', data)
}
export function getDel2(data) {
    return post('task/department/delDepart', data)
}
export function getDelState(data) {
    return post('task/department/editState', data)
}
export function getAdd(data) {
    return post('task/department/addDepar', data)
}
export function getOption(data) {
    return post('task/department/list', data)
}
export function getEmployee(data) {
    return post('task/department/employee', data)
}
export function getList() {
    return post('task/department/list')
}
export function getNavList() {
    return post('task/department/select')
}
// 获取人员列表
export function getStaff(data) {
    return post('admin/department/employeeList', data)
}
// 获取人员权限

export function getStaffRight(data) {
    return post('admin/department/getEmployeePower', data)
}

// 修改人员权限
export function editStaffRight(data) {
    return post('admin/department/editEmployeePower', data)
}