<template>
  <div class="user">
    <el-card>
      <div class="task-menu-box">
        <h2>职能菜单管理</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="4">
              <el-input placeholder="请输入内容" v-model="inputSearch">
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="search(inputSearch)"
                ></el-button>
              </el-input>
            </el-col>
            <el-col style="width:150px" :span="4">
              <el-button type="primary" @click="allAdd()"
                >添加职能菜单</el-button
              >
            </el-col>
            <el-col :span="4">
              <el-button type="success" @click="perDetail()"
                >人员权限批量编辑</el-button
              >
            </el-col>
            <el-col :span="4">
              <el-button type="warning" @click="dialogLog.state = true"
                >查看日志</el-button
              >
            </el-col>
          </el-row>
          <!-- 重构的树形表格 -->
          <el-table
            v-loading="tableLoading"
            class="task-table"
            :data="treelist"
            show-checkbox
            :expand-on-click-node="false"
            row-key="id"
            :tree-props="{ children: 'son', hasChildren: 'hasChildren' }"
          >
            <el-table-column prop="name" label="职能权限名称">
              <template slot-scope="scope">
                <span :style="{ color: scope.row.route == 1 ? 'red' : '' }">
                  <i :class="scope.row.icon"></i> {{ scope.row.label }}
                  {{ scope.row.name }} {{ scope.row.hierarchy }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="state" label="职能菜单状态">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.state"
                  :active-value="1"
                  :inactive-value="0"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @click.native="changeOpen(scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column width="260" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="success"
                  icon="el-icon-plus"
                  @click="pAdd(scope.row)"
                  >增加</el-button
                >

                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="remove(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- 表格 -->
          <!-- <el-tree
            :draggable="false"
            :default-expanded-keys="openList"
            @node-expand="expandNode"
            @node-collapse="collapseNode"
            style="width: 600px; margin-top: 10px"
            :data="treelist"
            :props="defaultProps"
            node-key="id"
            ref="tree"
            highlight-current
            :expand-on-click-node="false"
          >
            <div
              class="custom-tree-node"
              :style="{ background: node.level % 2 == 0 ? '#ebeef5' : '' }"
              slot-scope="{ node, data }"
            >
              <span :style="{ color: data.route == 1 ? 'red' : '' }">
                <i :class="data.icon"></i> {{ node.label }}
                {{ node.level }}</span
              >
              <span>
                <el-switch
                  :width="30"
                  style="margin-right: 20px"
                  @change="changeState(node, data.id)"
                  v-model="data.state"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
                  :disabled="showState ? true : false"
                >
                </el-switch>
                <el-button type="text" size="mini" @click="pAdd(node, data)">
                  增加
                </el-button>
                <el-button
                  type="text"
                  size="mini"
                  @click="handleEdit(node, data)"
                >
                  编辑
                </el-button>
                <el-button type="text" size="mini" @click="remove(node, data)">
                  删除
                </el-button>
              </span>
            </div>
          </el-tree> -->
        </div>
      </div>
    </el-card>
    <!-- 更改状态提示框 -->
    <el-dialog
      :visible.sync="changeDialogVisible"
      :before-close="handleChangeClose"
      width="30%"
      title="提示"
    >
      <div class="content">
        <div class="warning-icon" />
        <span class="text">{{ warningText }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="mini"
          @click="
            changeDialogVisible = false
            catalogue()
          "
          >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="confirmChangeState"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 修改用户 -->
    <el-dialog
      :visible.sync="dialogFormVisible"
      append-to-body
      @close="userClose(form)"
    >
      <div slot="title" class="dialog-footer">
        <div class="title" v-show="state">添加菜单</div>
        <div class="title" v-show="!state">修改菜单</div>
      </div>
      <div slot="header" class="dialog-footer">
        <el-button @click="closeEditStaffForm()" size="small">取 消</el-button>
        <el-button type="primary" @click="edit" size="small" v-if="!state"
          >修 改</el-button
        >
        <el-button
          type="primary"
          @click="userAddSubmit(form)"
          v-if="state"
          size="small"
          >添 加</el-button
        >
      </div>
      <el-form :model="form">
        <el-form-item label="">
          <div class="btn-auto">
            <el-radio-group v-model="form.route" size="medium">
              <el-radio-button :label="1">有页面</el-radio-button>
              <el-radio-button :label="2">无页面</el-radio-button>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item
          label="上级菜单"
          :label-width="formLabelWidth"
          v-if="!state"
        >
          <el-cascader
            ref="myCascader"
            :options="options"
            :props="optionProps"
            v-model="form.pid"
            @change="handleChange"
            filterable
            clearable
          >
          </el-cascader>
        </el-form-item>
        <!-- <el-form-item label="添加人员" :label-width="formLabelWidth">
          <el-select v-model="form.uid" multiple placeholder="请选择">
            <el-option
              v-for="item in options2"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="菜单名称" :label-width="formLabelWidth">
          <el-input v-model="form.name" @change="handleInputChange"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="edit" size="small" v-if="!state"
          >修 改</el-button
        >
        <el-button
          type="primary"
          @click="userAddSubmit(form)"
          v-if="state"
          size="small"
          >添 加</el-button
        >
      </div>
    </el-dialog>
    <!-- 删除用户提示 -->
    <el-dialog title="删除菜单" :visible.sync="dialogVisible3" width="30%">
      <span>{{ userdelinfo.name }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible3 = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="getDel" size="small">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 人员编辑模态框 -->
    <el-dialog
      title="人员权限批量编辑"
      :before-close="handleEditStaffRightClose"
      :visible.sync="staffDialogVisible"
    >
      <div class="custom-tree-container">
        <div class="block">
          <!-- <el-tree
            :data="options2"
            :props="defaultProps"
            node-key="id"
            default-expand-all
            :expand-on-click-node="false"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label }}</span>
              <span>
                <el-button
                  type="text"
                  size="mini"
                  @click="() => detailPer(data)"
                >
                  编辑
                </el-button>
              </span>
            </span>
          </el-tree> -->
          <el-table
            :data="staffList"
            tooltip-effect="dark"
            style="width: 100%"
            class="staff-table"
          >
            <el-table-column prop="nickname" label="姓名" />

            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <el-button
                  class="edit-button"
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="() => detailPer(scope.row)"
                  >编辑
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            :page-sizes="[10, 20, 30]"
            layout="total, sizes, prev, pager, next"
            :page-size="query.page_num"
            style="margin-top:15px"
            background
            :pager-count="15"
            :total="exp.num"
            @current-change="staffPageChange"
            @size-change="staffSizeChange"
          />
        </div>
      </div>
    </el-dialog>
    <!-- 任务编辑模态框 -->
    <el-dialog
      title="人员关联任务列表"
      v-dialogDrag
      :visible.sync="dialogVisible5"
      :before-close="handleStaffRightTreeClose"
    >
      <div class="custom-tree-container">
        <div class="block">
          <el-tree
            :data="treelist"
            :props="defaultProps"
            :current-node-key="currentNodeKey"
            :default-checked-keys="defaultCheckedKeys"
            node-key="id"
            ref="staffTreeRef"
            show-checkbox
            :expand-on-click-node="false"
          >
            <span class="custom-tree-node" slot-scope="{ node }">
              <span>{{ node.label }}</span>
            </span>
          </el-tree>
          <div slot="footer" class="dialog-detail">
            <el-button @click="closeStaffRightTree" size="small"
              >取 消</el-button
            >
            <el-button type="primary" @click="editPer" size="small"
              >修改权限</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
    <all-log :dialogLog="dialogLog" />
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Breadcrumb from '@/components/breadcrumb.vue'
import {
  getEdit,
  getDel2,
  getDelState,
  getAdd,
  getOption,
  getList,
  getEmployee,
  getNavList,
  getStaff,
  getStaffRight,
  editStaffRight,
} from '@/api/catalog/taskmenu'
import { changeArr } from '@/utils/utils.js'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Breadcrumb },
  data() {
    //这里存放数据
    return {
      dialogLog: {
        state: false,
      },
      tableLoading: false,
      openList: [],
      state: true,
      showState: false,
      //配置
      optionProps: {
        emitPath: false,
        checkStrictly: true,
        value: 'id',
        label: 'name',
        children: 'son',
      },
      //总页数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: '',
        page_code: 1,
        page_num: 7,
      },
      //导航
      nav: [],
      //单选列表
      radiolist: [
        {
          id: 1,
          name: '一级',
        },
        {
          id: 2,
          name: '二级',
        },
      ],
      empForm: { id: '' },
      //上级列表
      options: [],
      options2: [],
      getNav: [],
      //即将删除的信息
      userdelinfo: {},
      // 添加信息表单
      form: {
        name: '',
        pid: 0,
        route: 2,
        uid: [],
      },
      //表单名称长度
      formLabelWidth: '120px',
      //控制弹窗删除
      dialogVisible3: false,
      warningText: '',
      //控制弹窗--修改
      dialogFormVisible2: false,
      //控制弹窗--新增
      dialogFormVisible: false,
      changeDialogVisible: false,
      //人员编辑列表
      staffDialogVisible: false,
      dialogVisible5: false,
      //搜索框
      inputSearch: '',
      //表格数据
      tableData: [],
      treelist: [],
      defaultProps: {
        children: 'son',
        label: 'name',
      },
      row: '',
      query: {
        page: 1,
        page_num: 10,
      },
      staffList: [],
      exp: {
        count: Number,
        num: 0,
      },
      // 编辑权限的树当前选中的节点
      currentNodeKey: '',
      // 编辑权限的树默认选中的节点

      defaultCheckedKeys: [],
      userId: '',
      editStaffRightQuery: {},
      // 判断表单是否修改
      isEdit: false,
      route: '',
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //编辑人员关联任务
    async editPer(row) {
      console.log('编辑人员关联任务')
      console.log(this.$refs.staffTreeRef.getCheckedKeys())
      const arr = this.$refs.staffTreeRef.getCheckedKeys()
      const halfArr = this.$refs.staffTreeRef.getHalfCheckedKeys()
      this.editStaffRightQuery = {
        uid: this.userId,
        power_arr: [...arr, ...halfArr],
      }
      const res = await editStaffRight(this.editStaffRightQuery)
      console.log(res)
      this.getStaff()
      this.catalogue()
      this.judge(res)
      this.dialogVisible5 = false
    },
    // 处理人员列表关闭
    handleEditStaffRightClose() {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.staffDialogVisible = false
          //   this.$nextTick(() => {
          //     this.$refs.staffTreeRef.setCheckedKeys([])
          //   })

          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    // 处理树关闭
    handleStaffRightTreeClose() {
      this.$confirm('确认关闭？')
        .then((_) => {
          //   this.$nextTick(() => {
          //     this.$refs.staffTreeRef.setCheckedKeys([])
          //   })

          this.closeStaffRightTree()
          done()
        })
        .catch((_) => {})
    },
    // 关闭树对话框
    closeStaffRightTree() {
      this.dialogVisible5 = false
      this.$nextTick(() => {
        this.$refs.staffTreeRef.setCheckedKeys([])
      })
    },
    closeEditStaffForm() {
      this.dialogFormVisible = false
    },
    // 部门状态修改
    async changeOpen(row) {
      console.log(row)
      this.row = row
      this.changeDialogVisible = true
      if (row.state === 0) {
        // 即将禁用
        this.warningText =
          '此操作将禁用该职能权限, 禁用所有功能不可用，并计入操作日志，是否继续?'
      } else if (row.state === 1) {
        // 即将开启
        this.warningText =
          '此操作启用用该职能权限,启用后开启所有功能，并计入操作日志，是否继续?'
      }
    },
    //打开人员编辑模态框
    perDetail() {
      this.staffDialogVisible = true
      this.getStaff()
    },
    // 获取人员列表
    async getStaff() {
      const res = await getStaff(this.query)
      console.log(res)
      this.exp.num = res.data.exp.count
      this.staffList = res.data.data
    },
    staffPageChange(value) {
      console.log(value)

      this.query.page = value
      this.getStaff()
    },
    staffSizeChange(value) {
      this.query.page_num = value
      this.getStaff()
    },
    // 处理人员列表回显问题
    async detailPer(row) {
      // console.log(row)
      // this.dialogVisible5 = true
      // const { data: res } = await getStaffRight({ uid: row.id })
      // console.log(res.data.power)
      // if (res.data.power) {
      //   const arr = res.data.power.split(',')
      //   const right = []
      //   arr.forEach(f=>{
      //       if(!this.$refs.staffTreeRef.getNode(f).childNodes||!this.$refs.staffTreeRef.getNode(f).childNodes.length){
      //           right.push(f)
      //       }
      //   })
      //   // this.defaultCheckedKeys = arr
      //   this.defaultCheckedKeys = right
      // } else {
      //   this.defaultCheckedKeys = []
      // }
      // this.userId = row.id
      console.log(row)
      this.dialogVisible5 = true
      const { data: res } = await getStaffRight({ uid: row.id })
      console.log(res.data.power)
      const right = []

      setTimeout(() => {
        this.$nextTick(() => {
          if (res.data.power !== null) {
            const arr = res.data.power.split(',')
            console.log(arr)
            console.log(this.$refs.staffTreeRef.getNode())
            arr.forEach((f) => {
              f = Number(f)

              if (
                !this.$refs.staffTreeRef.getNode(f) ||
                !this.$refs.staffTreeRef.getNode(f).childNodes.length
              ) {
                right.push(f)
              }
            })
            // this.defaultCheckedKeys = arr
            this.defaultCheckedKeys = right
            this.$refs.staffTreeRef.setCheckedKeys(this.defaultCheckedKeys)
            this.$forceUpdate()
          } else {
            this.defaultCheckedKeys = []
            this.$refs.staffTreeRef.setCheckedKeys(this.defaultCheckedKeys)
            this.$forceUpdate()
          }
        })
      }, 0)
      this.userId = row.id
    },

    handleChangeClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.changeDialogVisible = false
          this.catalogue()
          done()
        })
        .catch((_) => {})
    },
    async confirmChangeState() {
      const res = await getDelState({ id: this.row.id })
      this.judge(res)
      // this.$message.success(res.message)

      this.changeDialogVisible = false
      this.catalogue()
    },
    renderContent(h, { node, data, store }) {
      return (
        <span class="custom-tree-node">
          <span>{node.label}</span>
          <span>
            <el-button
              size="mini"
              type="text"
              style="text-align:right;"
              on-click={() => this.append(data)}
            >
              编辑
            </el-button>
          </span>
        </span>
      )
    },
    // 保存节点
    expandNode(data) {
      let flag = false
      this.openList.some((item) => {
        if (item === data.id) {
          flag = true
          return true
        }
      })
      if (!flag) {
        this.openList.push(data.id)
      }
    },
    // 删除节点
    collapseNode(data) {
      this.openList.some((item, i) => {
        if (item === data.id) {
          this.openList.length = i
        }
      })
    },
    expandNode2(data) {
      let arr = []
      arr.push(data)
      let list = changeArr(arr)
      list.forEach((data) => {
        this.openList.indexOf(data.id)
        if (this.openList.indexOf(data.id) == -1) {
          this.openList.push(data.id)
        } else {
          this.listRemove(data.id)
        }
      })
      console.log(this.openList)
    },
    collapseNode2(data) {
      this.listRemove(data.id)
      let val = data.id
      var index = this.openList.indexOf(val)
      if (index > -1) {
        this.openList.splice(index, 1)
      }
    },
    listRemove(val) {
      var index = this.openList.indexOf(val)
      if (index > -1) {
        this.openList.splice(index, 1)
      }
    },
    changeNode(before, after, inner, event) {
      console.log(before)
      console.log(after)
      console.log(inner)
      console.log(event)
    },
    async changeState(id) {
      this.showState = true
      console.log(this.showState)
      let info = await getDelState({ id: id })
      this.judge(info)
    },
    judge(info) {
      if (info.data.code == 1) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        this.dialogFormVisible = false
        this.catalogue()
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
      }
    },
    goTree() {
      this.$router.push('/department')
    },
    handleDragStart(node1) {
      console.log('drag start', node1)
    },
    // 级联选择器的改变事件
    handleChange(info) {
      console.log(this.options)
      console.log(info)
      if (info) {
        this.isEdit = true
      }
    },
    // 判断输入框的改变事件
    handleInputChange(e) {
      this.isEdit = true
    },
    //父级增加
    allAdd(node, data) {
      this.state = true
      this.dialogFormVisible = true
      this.getEmp()
    },
    pAdd(data) {
      this.state = true
      this.dialogFormVisible = true
      this.form.pid = data.id
      this.getEmp()
    },
    //修改每页数量
    sizeChange(value) {
      console.log(value)
      this.getInfo.page_num = value
      this.catalogue()
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value
      this.catalogue()
    },
    //编辑
    handleEdit(data) {
      this.state = false
      this.dialogFormVisible = true
      this.form = data
      this.route = this.form.route
      this.form.uid = []
      this.isEdit = false
      this.getEmp()
    },
    //删除
    remove(data) {
      // console.log(node)
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.getDel({ id: data.id, state: true })
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    //编辑
    async edit() {
      if (this.isEdit === false && this.route === this.form.route) {
        this.$message.info('没有修改任何数据，请修改后再提交')
        return
      } else {
        let info = await getEdit(this.form)
        this.judge(info)
      }
    },
    //删除
    handleDelete(row, form) {
      this.dialogVisible3 = true
      this.userdelinfo = row
    },
    async getDel(info2) {
      let info = await getDel2(info2)
      //   if (info.data.code == 0) {
      //     this.$message({
      //       message: info.data.message,
      //       type: 'success',
      //     })
      //     this.catalogue()
      //   } else {
      //     this.$message({
      //       showClose: true,
      //       message: info.data.message,
      //       type: 'error',
      //     })
      //     return
      //   }
      this.catalogue()
      this.dialogVisible3 = false
    },

    //搜索事件
    search(e) {
      console.log('搜索信息: ' + e)
    },
    //添加事件--提交事件
    async userAddSubmit(e) {
      let info = await getAdd(this.form)
      this.judge(info)
    },
    //添加事件 退出弹窗
    userClose(e) {
      this.form = {
        name: '',
        pid: 0,
        route: 2,
        uid: [],
      }
      this.options2 = []
      console.log('close')
    },
    //目录接口
    async catalogue() {
      this.tableLoading = true
      let option = await getOption()
      let list = await getList()
      this.options = option.data.data
      this.treelist = list.data.data
      this.showState = false
      this.getNavList()
      this.tableLoading = false
    },
    async getEmp() {
      this.empForm.id = this.form.pid
      console.log(this.form)
      console.log(this.empForm)
      let employee = await getEmployee(this.empForm)
      this.options2 = employee.data.data
    },
    async getNavList() {
      let info = await getNavList()
      info = info.data.data
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.catalogue()
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
}
</script>

<style scoped>
.task-menu-box {
  border-radius: 25px;
  box-shadow: -1px 3px 3px 3px #e6e6e6;
  overflow: hidden;
}
.task-menu-box h2 {
  text-align: center;
  font-weight: 400;
  color: black;
  background: #ddd;
  height: 50px;
  margin: 0;
  line-height: 50px;
}
.task-menu-box .box-bd {
  padding: 20px;
  box-sizing: border-box;
}
.page {
  margin-top: 20px;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-right: none;
  border-top: none;
  padding-left: 20px;
}
.btn-auto {
  width: 200px;
  margin: auto;
}
.dialog-detail {
  margin-top: 20px;
  text-align: right;
}
.content {
  display: flex;
  align-items: center;
}
.content .warning-icon {
  width: 44px;
  height: 32px;
  margin-right: 10px;
  background: url('../../../../assets/exclamationmark.png') no-repeat;
}
</style>
